// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-agents-index-js": () => import("./../../../src/pages/agents/index.js" /* webpackChunkName: "component---src-pages-agents-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-properties-[id]-js": () => import("./../../../src/pages/properties/[id].js" /* webpackChunkName: "component---src-pages-properties-[id]-js" */),
  "component---src-pages-properties-index-js": () => import("./../../../src/pages/properties/index.js" /* webpackChunkName: "component---src-pages-properties-index-js" */)
}

